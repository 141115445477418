import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** action_dispatch_uploaded_file */
  File: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

export type AcceptPartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of AcceptPartneringRequest */
export type AcceptPartneringRequestPayload = {
  __typename?: 'AcceptPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** User Activity */
export type Activity = {
  __typename?: 'Activity';
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  gameBundles: Array<GameBundle>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  partneringRequests: Array<SimplePartneringRequest>;
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type AddReferrerInput = {
  referralCode: Scalars['String'];
};

/** Autogenerated return type of AddReferrer */
export type AddReferrerPayload = {
  __typename?: 'AddReferrerPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String'];
  id: Scalars['ID'];
  supervisor: Scalars['Boolean'];
};

export type AuthorizationInput = {
  phoneNumber: Scalars['String'];
  phoneCode: Scalars['String'];
  registrationId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Authorization */
export type AuthorizationPayload = {
  __typename?: 'AuthorizationPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  pilot?: Maybe<Pilot>;
};

/** Bank Info */
export type BankInfo = {
  __typename?: 'BankInfo';
  bankAccountNo: Scalars['String'];
  bankName: Scalars['String'];
  country: Scalars['String'];
  ic: Scalars['String'];
  icImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rejectReason?: Maybe<Scalars['String']>;
  selfie?: Maybe<Scalars['String']>;
  state: BankInfoState;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum BankInfoState {
  Submitted = 'submitted',
  Approved = 'approved',
  PendingResubmission = 'pending_resubmission'
}

/** Banner */
export type Banner = {
  __typename?: 'Banner';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  image: Scalars['String'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type CancelPartneringRequestInput = {
  id: Scalars['ID'];
  reportReason?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CancelPartneringRequest */
export type CancelPartneringRequestPayload = {
  __typename?: 'CancelPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Chatroom */
export type Chatroom = {
  __typename?: 'Chatroom';
  chatroomHolder: ChatroomHolder;
  id: Scalars['ID'];
  messages: Array<Message>;
  pilot: SimpleUser;
  user: SimpleUser;
};

/** Chatroom Holder */
export type ChatroomHolder = GameBundle | PartneringRequest;

/** Check Game Account */
export type CheckGameAccount = {
  __typename?: 'CheckGameAccount';
  acceptedPartneringRequests: Array<SimplePartneringRequest>;
  approve: Scalars['Boolean'];
  game: SimpleGame;
  id: Scalars['ID'];
  onlineSchedules: Array<OnlineSchedule>;
  proficientHerosCount: Scalars['Int'];
  proficientRolesCount: Scalars['Int'];
  suspended: Scalars['Boolean'];
};

/** Check Last Payment */
export type CheckLastPayment = {
  __typename?: 'CheckLastPayment';
  creditTransaction?: Maybe<CreditTransaction>;
};

/** Check Pilot */
export type CheckPilot = {
  __typename?: 'CheckPilot';
  acceptedPartneringRequests: Array<SimplePartneringRequest>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  gameAccounts: Array<CheckGameAccount>;
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  suspend: Scalars['Boolean'];
};

/** Check Spins */
export type CheckSpins = {
  __typename?: 'CheckSpins';
  creditTransaction?: Maybe<CreditTransaction>;
};

export enum CodeState {
  CodeUnverified = 'code_unverified',
  CodeSubmitted = 'code_submitted',
  CodeApproved = 'code_approved',
  PendingCodeResubmission = 'pending_code_resubmission'
}

export type CompleteInput = {
  id: Scalars['ID'];
  completionScreenshot: Scalars['File'];
};

export type CompletePartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of CompletePartneringRequest */
export type CompletePartneringRequestPayload = {
  __typename?: 'CompletePartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Autogenerated return type of Complete */
export type CompletePayload = {
  __typename?: 'CompletePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type ConfirmCompletionInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ConfirmCompletion */
export type ConfirmCompletionPayload = {
  __typename?: 'ConfirmCompletionPayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type CreateInput = {
  gameId: Scalars['ID'];
  currentGameRankId: Scalars['ID'];
  targetGameRankId: Scalars['ID'];
  currentRankTier?: Maybe<Scalars['Int']>;
  targetRankTier?: Maybe<Scalars['Int']>;
  currentRankStar?: Maybe<Scalars['Int']>;
  targetRankStar?: Maybe<Scalars['Int']>;
  currentRankPoint?: Maybe<Scalars['Int']>;
  targetRankPoint?: Maybe<Scalars['Int']>;
  proficientHeroIds?: Maybe<Array<Scalars['ID']>>;
  proficientRoleIds?: Maybe<Array<Scalars['ID']>>;
  queueIds?: Maybe<Array<Scalars['ID']>>;
  perspectiveIds?: Maybe<Array<Scalars['ID']>>;
  remark?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

export type CreatePartneringRequestInput = {
  gameId: Scalars['ID'];
  gameRankId: Scalars['ID'];
  rankTier?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankPoint?: Maybe<Scalars['Int']>;
  proficientRoleIds?: Maybe<Array<Scalars['ID']>>;
  queueIds?: Maybe<Array<Scalars['ID']>>;
  perspectiveIds?: Maybe<Array<Scalars['ID']>>;
  promoCode?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreatePartneringRequest */
export type CreatePartneringRequestPayload = {
  __typename?: 'CreatePartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Autogenerated return type of Create */
export type CreatePayload = {
  __typename?: 'CreatePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

/** Credit Transaction */
export type CreditTransaction = {
  __typename?: 'CreditTransaction';
  amount: Scalars['Float'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  id: Scalars['ID'];
  spinnable: Scalars['Boolean'];
  state: Scalars['String'];
  targetId?: Maybe<Scalars['ID']>;
  targetType?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  transactionType: Transaction;
  userPrizes?: Maybe<Array<UserPrize>>;
};

/** Credit Wallet */
export type CreditWallet = {
  __typename?: 'CreditWallet';
  amount: Scalars['Float'];
  creditTransactions: Array<CreditTransaction>;
  id: Scalars['ID'];
  payouts: Array<Payout>;
  user: User;
};

export enum Day {
  Anytime = 'anytime',
  Weekdays = 'weekdays',
  WeekendsHolidays = 'weekends_holidays'
}

/** Error */
export type Error = {
  __typename?: 'Error';
  detail: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type ExpireGameBundleInput = {
  id: Scalars['ID'];
  reportReason?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ExpireGameBundle */
export type ExpireGameBundlePayload = {
  __typename?: 'ExpireGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};


/** Game */
export type Game = {
  __typename?: 'Game';
  developerName: Scalars['String'];
  gameRanks: Array<GameRank>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  minRank?: Maybe<GameRank>;
  name: Scalars['String'];
  partneringPenaltyRate: Scalars['Int'];
  partneringRate: Scalars['Int'];
  perspectives: Array<ProficientRole>;
  proficientHeros: Array<ProficientHero>;
  proficientRoles: Array<ProficientRole>;
  queues: Array<ProficientRole>;
  screenshotSampleUrl?: Maybe<Scalars['String']>;
  testPassingScore: Scalars['Int'];
};

/** Game Account */
export type GameAccount = {
  __typename?: 'GameAccount';
  accountId: Scalars['String'];
  approve: Scalars['Boolean'];
  codeState: CodeState;
  createdAt: Scalars['ISO8601DateTime'];
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  onlineSchedules: Array<OnlineSchedule>;
  pairableGameRanks: Array<GameRank>;
  pilot: Pilot;
  proficientHeros: Array<ProficientHero>;
  proficientRoles: Array<ProficientRole>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  rejectReason?: Maybe<Scalars['String']>;
  remindAt?: Maybe<Scalars['ISO8601DateTime']>;
  remindCount: Scalars['Int'];
  screenshotState: ScreenshotState;
  screenshotUrl?: Maybe<Array<Scalars['String']>>;
  suspended: Scalars['Boolean'];
  testRetryCount: Scalars['Int'];
  testScore?: Maybe<Scalars['Float']>;
  testState: TestState;
  typeformUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  verificationCode: Scalars['String'];
};

/** Game Bundle */
export type GameBundle = {
  __typename?: 'GameBundle';
  amount: Scalars['Float'];
  bookingId: Scalars['String'];
  chatroom?: Maybe<Chatroom>;
  completionScreenshotUrl?: Maybe<Scalars['String']>;
  completionTime?: Maybe<Scalars['ISO8601DateTime']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentGameRank: GameRank;
  currentRankPoint?: Maybe<Scalars['Int']>;
  currentRankStar?: Maybe<Scalars['Int']>;
  currentRankTier?: Maybe<Scalars['Int']>;
  estimatedDurationInHour?: Maybe<Scalars['Float']>;
  game: Game;
  id: Scalars['ID'];
  originalAmount: Scalars['Float'];
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<SimpleUser>;
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  proficientHeros: Array<ProficientHero>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reportReason?: Maybe<Scalars['String']>;
  reporter?: Maybe<SimpleUser>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: GameBundleState;
  targetGameRank: GameRank;
  targetRankPoint?: Maybe<Scalars['Int']>;
  targetRankStar?: Maybe<Scalars['Int']>;
  targetRankTier?: Maybe<Scalars['Int']>;
  user: SimpleUser;
};

export enum GameBundleState {
  Available = 'available',
  Expired = 'expired',
  Grabbed = 'grabbed',
  Started = 'started',
  Completed = 'completed',
  ConfirmedCompletion = 'confirmed_completion',
  Rated = 'rated',
  Reported = 'reported',
  Refunded = 'refunded',
  Reviewed = 'reviewed',
  ConfirmStarted = 'confirm_started',
  GamerStarted = 'gamer_started'
}

/** Game Rank */
export type GameRank = {
  __typename?: 'GameRank';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ordering: Scalars['Int'];
  rankPointMaxCount: Array<Scalars['Int']>;
  rankStarMaxCount?: Maybe<Scalars['Int']>;
  rankTierMaxCount?: Maybe<Scalars['Int']>;
};

export type GamerStartInput = {
  id: Scalars['ID'];
};

export type GamerStartPartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of GamerStartPartneringRequest */
export type GamerStartPartneringRequestPayload = {
  __typename?: 'GamerStartPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Autogenerated return type of GamerStart */
export type GamerStartPayload = {
  __typename?: 'GamerStartPayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export enum Gender {
  Male = 'male',
  Female = 'female'
}

export type GrabInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of Grab */
export type GrabPayload = {
  __typename?: 'GrabPayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};


export enum Language {
  En = 'en',
  Bm = 'bm',
  Cn = 'cn'
}

/** Chatroom Message */
export type Message = {
  __typename?: 'Message';
  _id: Scalars['ID'];
  chatroom: Chatroom;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  order: Scalars['Int'];
  text: Scalars['String'];
  user: SimpleUser;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept Partnering Request */
  acceptPartneringRequest?: Maybe<AcceptPartneringRequestPayload>;
  /** Add referrer */
  addReferrer?: Maybe<AddReferrerPayload>;
  /** Sign In or Up */
  authorization?: Maybe<AuthorizationPayload>;
  /** Cancel Partnering Request */
  cancelPartneringRequest?: Maybe<CancelPartneringRequestPayload>;
  /** Complete GameBundle */
  completeGameBundle?: Maybe<CompletePayload>;
  /** Complete Partnering Request */
  completePartneringRequest?: Maybe<CompletePartneringRequestPayload>;
  /** Confirm Completion Game Bundle */
  confirmCompletionGameBundle?: Maybe<ConfirmCompletionPayload>;
  /** Create Game Bundle */
  createGameBundle?: Maybe<CreatePayload>;
  /** Create Partnering Request */
  createPartneringRequest?: Maybe<CreatePartneringRequestPayload>;
  /** Cancel Game Bundle */
  expireGameBundle?: Maybe<ExpireGameBundlePayload>;
  /** Gamer start GameBundle */
  gamerStartGameBundle?: Maybe<GamerStartPayload>;
  /** Gamer start Partnering Request */
  gamerStartPartneringRequest?: Maybe<GamerStartPartneringRequestPayload>;
  /** Grab Game Bundle */
  grabGameBundle?: Maybe<GrabPayload>;
  /** Pilot complete Partnering Request */
  pilotCompletePartneringRequest?: Maybe<PilotCompletePartneringRequestPayload>;
  /** Pilot start GameBundle */
  pilotStart?: Maybe<PilotStartPayload>;
  /** Pilot start Partnering Request */
  pilotStartPartneringRequest?: Maybe<PilotStartPartneringRequestPayload>;
  /** Preview Game Bundle Amount */
  previewGameBundleAmount?: Maybe<PreviewAmountPayload>;
  /** Rate Game Bundle */
  rateGameBundle?: Maybe<RateGameBundlePayload>;
  /** Rate Partnering Request */
  ratePartneringRequest?: Maybe<RatePartneringRequestPayload>;
  /** Read Notification */
  readNotification?: Maybe<ReadNotificationPayload>;
  /** Reject Partnering Request */
  rejectPartneringRequest?: Maybe<RejectPartneringRequestPayload>;
  /** Remove Firebase Registration Id */
  removeFirebaseRegistrationId?: Maybe<RemoveRegistrationIdPayload>;
  /** Report GameBundle */
  reportGameBundle?: Maybe<ReportGameBundlePayload>;
  /** Report Partnering Request */
  reportPartneringRequest?: Maybe<ReportPartneringRequestPayload>;
  /** Request Payout Transaction */
  requestPayoutTransaction?: Maybe<RequestPayload>;
  /** Request Code */
  requestPhoneCode?: Maybe<RequestCodePayload>;
  /** Send Message */
  sendMessage?: Maybe<SendMessagePayload>;
  /** Set Online or Offline */
  setOnline?: Maybe<SetOnlinePayload>;
  /** Pilot start GameBundle */
  startGameBundle?: Maybe<StartPayload>;
  /** Pilot start Partnering Request */
  startPartneringRequest?: Maybe<StartPartneringRequestPayload>;
  /** Submit Bank Info */
  submitBankInfo?: Maybe<SubmitInfoPayload>;
  /** Submit Game Account Verification Code */
  submitGameAccountCode?: Maybe<SubmitCodePayload>;
  /** Submit Game Account Data */
  submitGameAccountData?: Maybe<SubmitDataPayload>;
  /** Submit Online Schedules */
  submitOnlineSchedules?: Maybe<SubmitOnlineSchedulesPayload>;
  /** Submit Proficient Heros */
  submitProficientHeros?: Maybe<SubmitProficientHerosPayload>;
  /** Submit Proficient Roles */
  submitProficientRoles?: Maybe<SubmitProficientRolesPayload>;
  /** Submit Rank Pairing */
  submitRankPairing?: Maybe<SubmitRankPairingPayload>;
  /** Top Up Credit Card */
  topUp?: Maybe<TopUpPayload>;
  /** Update Bank Info */
  updateBankInfo?: Maybe<UpdateInfoPayload>;
  /** Update Firebase Registration Ids */
  updateFirebaseRegistrationIds?: Maybe<UpdateRegistrationIdsPayload>;
  /** Update address */
  updateIpAddress?: Maybe<UpdateAddressPayload>;
  /** Update Profile */
  updateProfile?: Maybe<UpdatePayload>;
  /** Update user prize */
  updateUserPrize?: Maybe<UpdateUserPrizePayload>;
  /** Sign In or Up */
  userAuthorization?: Maybe<UserAuthorizationPayload>;
  /** Request Code */
  userRequestPhoneCode?: Maybe<UserRequestCodePayload>;
  /** User Submit Heros */
  userSubmitHeros?: Maybe<UserSubmitHerosPayload>;
};


export type MutationAcceptPartneringRequestArgs = {
  input: AcceptPartneringRequestInput;
};


export type MutationAddReferrerArgs = {
  input: AddReferrerInput;
};


export type MutationAuthorizationArgs = {
  input: AuthorizationInput;
};


export type MutationCancelPartneringRequestArgs = {
  input: CancelPartneringRequestInput;
};


export type MutationCompleteGameBundleArgs = {
  input: CompleteInput;
};


export type MutationCompletePartneringRequestArgs = {
  input: CompletePartneringRequestInput;
};


export type MutationConfirmCompletionGameBundleArgs = {
  input: ConfirmCompletionInput;
};


export type MutationCreateGameBundleArgs = {
  input: CreateInput;
};


export type MutationCreatePartneringRequestArgs = {
  input: CreatePartneringRequestInput;
};


export type MutationExpireGameBundleArgs = {
  input: ExpireGameBundleInput;
};


export type MutationGamerStartGameBundleArgs = {
  input: GamerStartInput;
};


export type MutationGamerStartPartneringRequestArgs = {
  input: GamerStartPartneringRequestInput;
};


export type MutationGrabGameBundleArgs = {
  input: GrabInput;
};


export type MutationPilotCompletePartneringRequestArgs = {
  input: PilotCompletePartneringRequestInput;
};


export type MutationPilotStartArgs = {
  input: PilotStartInput;
};


export type MutationPilotStartPartneringRequestArgs = {
  input: PilotStartPartneringRequestInput;
};


export type MutationPreviewGameBundleAmountArgs = {
  input: PreviewAmountInput;
};


export type MutationRateGameBundleArgs = {
  input: RateGameBundleInput;
};


export type MutationRatePartneringRequestArgs = {
  input: RatePartneringRequestInput;
};


export type MutationReadNotificationArgs = {
  input: ReadNotificationInput;
};


export type MutationRejectPartneringRequestArgs = {
  input: RejectPartneringRequestInput;
};


export type MutationRemoveFirebaseRegistrationIdArgs = {
  input: RemoveRegistrationIdInput;
};


export type MutationReportGameBundleArgs = {
  input: ReportGameBundleInput;
};


export type MutationReportPartneringRequestArgs = {
  input: ReportPartneringRequestInput;
};


export type MutationRequestPayoutTransactionArgs = {
  input: RequestInput;
};


export type MutationRequestPhoneCodeArgs = {
  input: RequestCodeInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationSetOnlineArgs = {
  input: SetOnlineInput;
};


export type MutationStartGameBundleArgs = {
  input: StartInput;
};


export type MutationStartPartneringRequestArgs = {
  input: StartPartneringRequestInput;
};


export type MutationSubmitBankInfoArgs = {
  input: SubmitInfoInput;
};


export type MutationSubmitGameAccountCodeArgs = {
  input: SubmitCodeInput;
};


export type MutationSubmitGameAccountDataArgs = {
  input: SubmitDataInput;
};


export type MutationSubmitOnlineSchedulesArgs = {
  input: SubmitOnlineSchedulesInput;
};


export type MutationSubmitProficientHerosArgs = {
  input: SubmitProficientHerosInput;
};


export type MutationSubmitProficientRolesArgs = {
  input: SubmitProficientRolesInput;
};


export type MutationSubmitRankPairingArgs = {
  input: SubmitRankPairingInput;
};


export type MutationTopUpArgs = {
  input: TopUpInput;
};


export type MutationUpdateBankInfoArgs = {
  input: UpdateInfoInput;
};


export type MutationUpdateFirebaseRegistrationIdsArgs = {
  input: UpdateRegistrationIdsInput;
};


export type MutationUpdateIpAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateInput;
};


export type MutationUpdateUserPrizeArgs = {
  input: UpdateUserPrizeInput;
};


export type MutationUserAuthorizationArgs = {
  input: UserAuthorizationInput;
};


export type MutationUserRequestPhoneCodeArgs = {
  input: UserRequestCodeInput;
};


export type MutationUserSubmitHerosArgs = {
  input: UserSubmitHerosInput;
};

/** Notifications */
export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  game?: Maybe<Game>;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  target?: Maybe<NotificationTarget>;
};

/** Notification Target */
export type NotificationTarget = CreditTransaction | GameBundle | PartneringRequest | SuspendRequest;

/** Online Schedule */
export type OnlineSchedule = {
  __typename?: 'OnlineSchedule';
  dayType: Day;
  hours?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Pairable Game Account */
export type PairableGameAccount = {
  __typename?: 'PairableGameAccount';
  id: Scalars['ID'];
  pairableGameRanks: Array<SimpleGameRank>;
};

/** Pairable Pilot */
export type PairablePilot = {
  __typename?: 'PairablePilot';
  acceptedPartneringRequests: Array<SimplePartneringRequest>;
  gameAccount?: Maybe<PairableGameAccount>;
  id: Scalars['ID'];
  online: Scalars['Boolean'];
};


/** Pairable Pilot */
export type PairablePilotGameAccountArgs = {
  gameId: Scalars['ID'];
};

/** Partnering Request */
export type PartneringRequest = {
  __typename?: 'PartneringRequest';
  amount: Scalars['Float'];
  chatroom?: Maybe<Chatroom>;
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  durationHour: Scalars['Float'];
  game: Game;
  gameRank: GameRank;
  id: Scalars['ID'];
  originalAmount: Scalars['Float'];
  perspectives?: Maybe<Array<ProficientRole>>;
  pilot?: Maybe<SimpleUser>;
  pilotGameAccount?: Maybe<SimpleGameAccount>;
  proficientRoles?: Maybe<Array<ProficientRole>>;
  promoApplied: Scalars['Boolean'];
  promoCode?: Maybe<Scalars['String']>;
  queues?: Maybe<Array<ProficientRole>>;
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  ratingRemark?: Maybe<Scalars['String']>;
  reportReason?: Maybe<Scalars['String']>;
  reporter?: Maybe<SimpleUser>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: PartneringRequestState;
  totalAmount: Scalars['Float'];
  totalDurationInMinutes?: Maybe<Scalars['Float']>;
  totalOriginalAmount: Scalars['Float'];
  user: SimpleUser;
};

export enum PartneringRequestState {
  Available = 'available',
  Cancelled = 'cancelled',
  Started = 'started',
  PaymentOnHold = 'payment_on_hold',
  Completed = 'completed',
  Rated = 'rated',
  Reported = 'reported',
  Matched = 'matched',
  Refunded = 'refunded',
  Reviewed = 'reviewed',
  ConfirmStarted = 'confirm_started',
  GamerStarted = 'gamer_started'
}

/** Payout */
export type Payout = {
  __typename?: 'Payout';
  amount: Scalars['Float'];
  approve: Scalars['Boolean'];
  bank: Scalars['String'];
  bankAccountNo: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  email: Scalars['String'];
  ic: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
  pilot: Pilot;
  recipient?: Maybe<Scalars['String']>;
  rejectReason?: Maybe<Scalars['String']>;
  state?: Maybe<PayoutState>;
};

export enum PayoutState {
  Pending = 'pending',
  Processing = 'processing',
  TransactionSuccess = 'transaction_success',
  TransactionFailed = 'transaction_failed',
  Rejected = 'rejected'
}

/** Pilot */
export type Pilot = {
  __typename?: 'Pilot';
  acceptedPartneringRequests: Array<PartneringRequest>;
  aggregateRating: Scalars['Float'];
  availableGameBundles: Array<GameBundle>;
  avatarUrl?: Maybe<Scalars['String']>;
  averageRespondTime?: Maybe<Scalars['Int']>;
  bankInfo?: Maybe<BankInfo>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  chatroom?: Maybe<Chatroom>;
  completeGameBundlesCount: Scalars['Int'];
  completePartneringRequestsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  email?: Maybe<Scalars['String']>;
  gameAccount?: Maybe<GameAccount>;
  gameAccounts: Array<GameAccount>;
  gameBundles: Array<GameBundle>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  nickname?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  online: Scalars['Boolean'];
  partneringRequest?: Maybe<PartneringRequest>;
  partneringRequests: Array<PartneringRequest>;
  phoneNumber: Scalars['String'];
  pilotChatroom?: Maybe<Chatroom>;
  referralCode: Scalars['String'];
  signInCount: Scalars['Int'];
  streamer: Scalars['Boolean'];
  suspend: Scalars['Boolean'];
  suspendRequests?: Maybe<Array<SuspendRequest>>;
  trained: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
  userGameAccounts: Array<UserGameAccount>;
};


/** Pilot */
export type PilotAvailableGameBundlesArgs = {
  gameId?: Maybe<Scalars['ID']>;
};


/** Pilot */
export type PilotChatroomArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotGameAccountArgs = {
  gameId: Scalars['ID'];
};


/** Pilot */
export type PilotGameBundlesArgs = {
  states?: Maybe<Array<GameBundleState>>;
};


/** Pilot */
export type PilotNotificationsArgs = {
  gameId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};


/** Pilot */
export type PilotPartneringRequestArgs = {
  id: Scalars['ID'];
};


/** Pilot */
export type PilotPilotChatroomArgs = {
  id: Scalars['ID'];
};

/** Pilot Banner */
export type PilotBanner = {
  __typename?: 'PilotBanner';
  active: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  image: Scalars['String'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type PilotCompletePartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of PilotCompletePartneringRequest */
export type PilotCompletePartneringRequestPayload = {
  __typename?: 'PilotCompletePartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type PilotStartInput = {
  id: Scalars['ID'];
};

export type PilotStartPartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of PilotStartPartneringRequest */
export type PilotStartPartneringRequestPayload = {
  __typename?: 'PilotStartPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Autogenerated return type of PilotStart */
export type PilotStartPayload = {
  __typename?: 'PilotStartPayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

/** Game Bundle Preview Amount */
export type PreviewAmount = {
  __typename?: 'PreviewAmount';
  priceBreakdown?: Maybe<Array<PriceBreakdown>>;
  totalAmount: Scalars['Float'];
};

export type PreviewAmountInput = {
  gameId: Scalars['ID'];
  currentGameRankId: Scalars['ID'];
  targetGameRankId: Scalars['ID'];
  currentRankTier?: Maybe<Scalars['Int']>;
  targetRankTier?: Maybe<Scalars['Int']>;
  currentRankStar?: Maybe<Scalars['Int']>;
  targetRankStar?: Maybe<Scalars['Int']>;
  currentRankPoint?: Maybe<Scalars['Int']>;
  targetRankPoint?: Maybe<Scalars['Int']>;
  promoCode?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PreviewAmount */
export type PreviewAmountPayload = {
  __typename?: 'PreviewAmountPayload';
  amount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Error>>;
  previewAmount?: Maybe<PreviewAmount>;
  promoCodeApply?: Maybe<PromoCodeApply>;
};

/** Game Bundle Price Breakdown */
export type PriceBreakdown = {
  __typename?: 'PriceBreakdown';
  higherPoint?: Maybe<Scalars['Int']>;
  higherRank: Scalars['String'];
  higherStar?: Maybe<Scalars['Int']>;
  higherTier: Scalars['Int'];
  lowerPoint?: Maybe<Scalars['Int']>;
  lowerRank: Scalars['String'];
  lowerStar?: Maybe<Scalars['Int']>;
  lowerTier: Scalars['Int'];
  subTotal: Scalars['Float'];
};

/** Prize */
export type Prize = {
  __typename?: 'Prize';
  createdAt: Scalars['ISO8601DateTime'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  prizeName: Scalars['String'];
  prizeType?: Maybe<PrizeType>;
  value: Scalars['Float'];
};

export enum PrizeType {
  GameItem = 'game_item',
  GameCredit = 'game_credit',
  GrandPrize = 'grand_prize',
  FirstPrize = 'first_prize',
  SecondPrize = 'second_prize',
  ThirdPrize = 'third_prize',
  EliteSkin = 'elite_skin',
  EpicSkin = 'epic_skin',
  PromoCode = 'promo_code'
}

/** Proficient Hero */
export type ProficientHero = {
  __typename?: 'ProficientHero';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  proficientRole: ProficientRole;
};

/** Proficient Role */
export type ProficientRole = {
  __typename?: 'ProficientRole';
  game: Game;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  kind?: Maybe<ProficientRoleKind>;
  name: Scalars['String'];
  proficientHeros?: Maybe<Array<ProficientHero>>;
  selectedImageUrl?: Maybe<Scalars['String']>;
};

export enum ProficientRoleKind {
  HeroRole = 'hero_role',
  Queue = 'queue',
  Perspective = 'perspective'
}

/** Promo Code */
export type PromoCode = {
  __typename?: 'PromoCode';
  active: Scalars['Boolean'];
  amount: Scalars['Int'];
  availableFor: Scalars['String'];
  code: Scalars['String'];
  gameType: Scalars['String'];
  id: Scalars['ID'];
  minimumAmount: Scalars['Int'];
  redeemLimit?: Maybe<Scalars['Int']>;
  userIds?: Maybe<Array<Scalars['String']>>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Promo Code Apply */
export type PromoCodeApply = {
  __typename?: 'PromoCodeApply';
  amount?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  checkLastPayment?: Maybe<CheckLastPayment>;
  checkPilot?: Maybe<CheckPilot>;
  checkSpins?: Maybe<CheckSpins>;
  creditTransaction?: Maybe<CreditTransaction>;
  game?: Maybe<Game>;
  gameBundle?: Maybe<GameBundle>;
  gamerBanners?: Maybe<Array<Banner>>;
  games?: Maybe<Array<Game>>;
  me?: Maybe<User>;
  message?: Maybe<Message>;
  onlineSchedules: Array<OnlineSchedule>;
  pairablePilotMe?: Maybe<PairablePilot>;
  partneringRequest?: Maybe<PartneringRequest>;
  pilotBanners?: Maybe<Array<PilotBanner>>;
  pilotMe?: Maybe<Pilot>;
  prizes?: Maybe<Array<Prize>>;
  simpleGame?: Maybe<SimpleGameWithRank>;
  simplePilotMe?: Maybe<SimplePilot>;
  userActivity?: Maybe<Activity>;
  userPrize?: Maybe<UserPrize>;
  userPromoCode?: Maybe<UserPromoCode>;
};


export type QueryCreditTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryGameArgs = {
  id: Scalars['ID'];
};


export type QueryGameBundleArgs = {
  id: Scalars['ID'];
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryPartneringRequestArgs = {
  id: Scalars['ID'];
};


export type QuerySimpleGameArgs = {
  id: Scalars['ID'];
};


export type QueryUserPrizeArgs = {
  id: Scalars['ID'];
};


export type QueryUserPromoCodeArgs = {
  code: Scalars['String'];
  userId: Scalars['String'];
};

export type RateGameBundleInput = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  ratingRemark?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RateGameBundle */
export type RateGameBundlePayload = {
  __typename?: 'RateGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type RatePartneringRequestInput = {
  id: Scalars['ID'];
  rating: Scalars['Int'];
  ratingRemark?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RatePartneringRequest */
export type RatePartneringRequestPayload = {
  __typename?: 'RatePartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type ReadNotificationInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of ReadNotification */
export type ReadNotificationPayload = {
  __typename?: 'ReadNotificationPayload';
  errors?: Maybe<Array<Error>>;
  notification?: Maybe<Notification>;
};

/** Referrer */
export type Referrer = {
  __typename?: 'Referrer';
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nickname?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type RejectPartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of RejectPartneringRequest */
export type RejectPartneringRequestPayload = {
  __typename?: 'RejectPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type RemoveRegistrationIdInput = {
  id: Scalars['String'];
};

/** Autogenerated return type of RemoveRegistrationId */
export type RemoveRegistrationIdPayload = {
  __typename?: 'RemoveRegistrationIdPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<SimpleUser>;
};

export type ReportGameBundleInput = {
  id: Scalars['ID'];
  reportReason: Scalars['String'];
};

/** Autogenerated return type of ReportGameBundle */
export type ReportGameBundlePayload = {
  __typename?: 'ReportGameBundlePayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type ReportPartneringRequestInput = {
  id: Scalars['ID'];
  reportReason: Scalars['String'];
};

/** Autogenerated return type of ReportPartneringRequest */
export type ReportPartneringRequestPayload = {
  __typename?: 'ReportPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

export type RequestCodeInput = {
  phoneNumber: Scalars['String'];
};

/** Autogenerated return type of RequestCode */
export type RequestCodePayload = {
  __typename?: 'RequestCodePayload';
  errors?: Maybe<Array<Error>>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type RequestInput = {
  amount: Scalars['Float'];
  version?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Request */
export type RequestPayload = {
  __typename?: 'RequestPayload';
  errors?: Maybe<Array<Error>>;
  payout?: Maybe<Payout>;
};

export enum ScreenshotState {
  ScreenshotUnverified = 'screenshot_unverified',
  ScreenshotSubmitted = 'screenshot_submitted',
  ScreenshotApproved = 'screenshot_approved',
  PendingScreenshotResubmission = 'pending_screenshot_resubmission'
}

export type SendMessageInput = {
  chatroomId: Scalars['ID'];
  text: Scalars['String'];
  order: Scalars['Int'];
};

/** Autogenerated return type of SendMessage */
export type SendMessagePayload = {
  __typename?: 'SendMessagePayload';
  errors?: Maybe<Array<Error>>;
  message?: Maybe<Message>;
};

export type SetOnlineInput = {
  online: Scalars['Boolean'];
  version?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SetOnline */
export type SetOnlinePayload = {
  __typename?: 'SetOnlinePayload';
  errors?: Maybe<Array<Error>>;
  pilot?: Maybe<Pilot>;
};

/** Simple Game */
export type SimpleGame = {
  __typename?: 'SimpleGame';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Simple Game Account */
export type SimpleGameAccount = {
  __typename?: 'SimpleGameAccount';
  gameRank: SimpleGameRank;
  id: Scalars['ID'];
  rankPoint?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankTier?: Maybe<Scalars['Int']>;
};

/** Simple Game Rank */
export type SimpleGameRank = {
  __typename?: 'SimpleGameRank';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Simple Game */
export type SimpleGameWithRank = {
  __typename?: 'SimpleGameWithRank';
  gameRanks: Array<SimpleGameRank>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Simple Partnering Request */
export type SimplePartneringRequest = {
  __typename?: 'SimplePartneringRequest';
  completeTime?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  game: SimpleGame;
  gameRank: SimpleGameRank;
  id: Scalars['ID'];
  ratingRemark?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['ISO8601DateTime']>;
  state: PartneringRequestState;
  totalDurationInMinutes?: Maybe<Scalars['Float']>;
};

/** Simple Pilot */
export type SimplePilot = {
  __typename?: 'SimplePilot';
  aggregateRating: Scalars['Float'];
  avatarUrl?: Maybe<Scalars['String']>;
  averageRespondTime?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditBalance: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  nickname?: Maybe<Scalars['String']>;
  online: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  referralCode: Scalars['String'];
  signInCount: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Simple User */
export type SimpleUser = {
  __typename?: 'SimpleUser';
  _id: Scalars['ID'];
  avatarUrl?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  language: Language;
  nickname?: Maybe<Scalars['String']>;
};

export type StartInput = {
  id: Scalars['ID'];
};

export type StartPartneringRequestInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of StartPartneringRequest */
export type StartPartneringRequestPayload = {
  __typename?: 'StartPartneringRequestPayload';
  errors?: Maybe<Array<Error>>;
  partneringRequest?: Maybe<PartneringRequest>;
};

/** Autogenerated return type of Start */
export type StartPayload = {
  __typename?: 'StartPayload';
  errors?: Maybe<Array<Error>>;
  gameBundle?: Maybe<GameBundle>;
};

export type SubmitCodeInput = {
  id: Scalars['ID'];
};

/** Autogenerated return type of SubmitCode */
export type SubmitCodePayload = {
  __typename?: 'SubmitCodePayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type SubmitDataInput = {
  accountId: Scalars['String'];
  gameId: Scalars['ID'];
  gameRankId?: Maybe<Scalars['ID']>;
  pairableGameRankIds?: Maybe<Array<Scalars['ID']>>;
  rankTier?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankPoint?: Maybe<Scalars['Int']>;
  screenshot: Array<Scalars['File']>;
};

/** Autogenerated return type of SubmitData */
export type SubmitDataPayload = {
  __typename?: 'SubmitDataPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type SubmitInfoInput = {
  name: Scalars['String'];
  bankName: Scalars['String'];
  bankAccountNo: Scalars['String'];
  country: Scalars['String'];
  ic: Scalars['String'];
  icImage: Scalars['File'];
  selfie: Scalars['File'];
};

/** Autogenerated return type of SubmitInfo */
export type SubmitInfoPayload = {
  __typename?: 'SubmitInfoPayload';
  bankInfo?: Maybe<BankInfo>;
  errors?: Maybe<Array<Error>>;
};

export type SubmitOnlineSchedulesInput = {
  gameId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of SubmitOnlineSchedules */
export type SubmitOnlineSchedulesPayload = {
  __typename?: 'SubmitOnlineSchedulesPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type SubmitProficientHerosInput = {
  gameId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of SubmitProficientHeros */
export type SubmitProficientHerosPayload = {
  __typename?: 'SubmitProficientHerosPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type SubmitProficientRolesInput = {
  gameId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of SubmitProficientRoles */
export type SubmitProficientRolesPayload = {
  __typename?: 'SubmitProficientRolesPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type SubmitRankPairingInput = {
  gameId: Scalars['ID'];
  gameRankIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of SubmitRankPairing */
export type SubmitRankPairingPayload = {
  __typename?: 'SubmitRankPairingPayload';
  errors?: Maybe<Array<Error>>;
  gameAccount?: Maybe<GameAccount>;
};

export type SuspendGamerRequest = {
  __typename?: 'SuspendGamerRequest';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  state: SuspendRequestState;
};

export type SuspendRequest = {
  __typename?: 'SuspendRequest';
  admin: Admin;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
  state: SuspendRequestState;
};

export enum SuspendRequestState {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected'
}

/** Target */
export type Target = GameBundle | PartneringRequest;

export enum TestState {
  TestPending = 'test_pending',
  TestPassed = 'test_passed',
  TestFailed = 'test_failed',
  TestNonRetryable = 'test_non_retryable'
}

export type TopUpInput = {
  amount: Scalars['Int'];
  version?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TopUp */
export type TopUpPayload = {
  __typename?: 'TopUpPayload';
  errors?: Maybe<Array<Error>>;
  sessionId?: Maybe<Scalars['String']>;
};

export enum Transaction {
  Payout = 'payout',
  Spend = 'spend',
  Earn = 'earn',
  Refund = 'refund',
  Referral = 'referral',
  Topup = 'topup',
  RefundDeduction = 'refund_deduction'
}

export type UpdateAddressInput = {
  ipAddress: Scalars['String'];
};

/** Autogenerated return type of UpdateAddress */
export type UpdateAddressPayload = {
  __typename?: 'UpdateAddressPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<User>;
};

export type UpdateInfoInput = {
  name?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAccountNo?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  icImage?: Maybe<Scalars['File']>;
  selfie?: Maybe<Scalars['File']>;
};

/** Autogenerated return type of UpdateInfo */
export type UpdateInfoPayload = {
  __typename?: 'UpdateInfoPayload';
  bankInfo?: Maybe<BankInfo>;
  errors?: Maybe<Array<Error>>;
};

export type UpdateInput = {
  avatar?: Maybe<Scalars['File']>;
  nickname: Scalars['String'];
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  language?: Maybe<Language>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of Update */
export type UpdatePayload = {
  __typename?: 'UpdatePayload';
  errors?: Maybe<Array<Error>>;
  pilot?: Maybe<Pilot>;
  user?: Maybe<User>;
};

export type UpdateRegistrationIdsInput = {
  ids: Scalars['String'];
};

/** Autogenerated return type of UpdateRegistrationIds */
export type UpdateRegistrationIdsPayload = {
  __typename?: 'UpdateRegistrationIdsPayload';
  errors?: Maybe<Array<Error>>;
  user?: Maybe<SimpleUser>;
};

export type UpdateUserPrizeInput = {
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  skinName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  inGameName?: Maybe<Scalars['String']>;
  inGameId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUserPrize */
export type UpdateUserPrizePayload = {
  __typename?: 'UpdateUserPrizePayload';
  errors?: Maybe<Array<Error>>;
  userPrize?: Maybe<UserPrize>;
};

/** User */
export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['ISO8601DateTime']>;
  chatroom?: Maybe<Chatroom>;
  createdAt: Scalars['ISO8601DateTime'];
  creditWallet: CreditWallet;
  email?: Maybe<Scalars['String']>;
  gameBundles: Array<GameBundle>;
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  language: Language;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  nickname?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  partneringRequests: Array<PartneringRequest>;
  phoneNumber: Scalars['String'];
  referralCode: Scalars['String'];
  referrals?: Maybe<Array<User>>;
  referrer?: Maybe<Referrer>;
  referrerId?: Maybe<Scalars['String']>;
  signInCount: Scalars['Int'];
  suspend: Scalars['Boolean'];
  suspendGamerRequests?: Maybe<Array<SuspendGamerRequest>>;
  updatedAt: Scalars['ISO8601DateTime'];
  userGameAccounts: Array<UserGameAccount>;
  userPrizes?: Maybe<Array<UserPrize>>;
};


/** User */
export type UserChatroomArgs = {
  id: Scalars['ID'];
};


/** User */
export type UserNotificationsArgs = {
  gameId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
};

export type UserAuthorizationInput = {
  phoneNumber: Scalars['String'];
  phoneCode: Scalars['String'];
  registrationId?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UserAuthorization */
export type UserAuthorizationPayload = {
  __typename?: 'UserAuthorizationPayload';
  errors?: Maybe<Array<Error>>;
  jwt?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** User Game Account */
export type UserGameAccount = {
  __typename?: 'UserGameAccount';
  game: Game;
  id: Scalars['ID'];
  proficientHeros: Array<ProficientHero>;
};

/** User Prize */
export type UserPrize = {
  __typename?: 'UserPrize';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  creditTransactionId: Scalars['ID'];
  fullName?: Maybe<Scalars['String']>;
  icNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inGameId?: Maybe<Scalars['String']>;
  inGameName?: Maybe<Scalars['String']>;
  prize?: Maybe<Prize>;
  probabilities: Array<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  skinName?: Maybe<Scalars['String']>;
  state: UserPrizeState;
  userId: Scalars['ID'];
  userNickname: Scalars['String'];
};

export enum UserPrizeState {
  PendingSpin = 'pending_spin',
  PendingInfo = 'pending_info',
  Processing = 'processing',
  Delivered = 'delivered',
  Cancelled = 'cancelled'
}

/** UserPromoCode */
export type UserPromoCode = {
  __typename?: 'UserPromoCode';
  id: Scalars['ID'];
  promoCode: PromoCode;
  target: Target;
  user: User;
};

export type UserRequestCodeInput = {
  phoneNumber: Scalars['String'];
};

/** Autogenerated return type of UserRequestCode */
export type UserRequestCodePayload = {
  __typename?: 'UserRequestCodePayload';
  errors?: Maybe<Array<Error>>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UserSubmitHerosInput = {
  gameId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of UserSubmitHeros */
export type UserSubmitHerosPayload = {
  __typename?: 'UserSubmitHerosPayload';
  errors?: Maybe<Array<Error>>;
  userGameAccount?: Maybe<UserGameAccount>;
};

export type BankInfoFragment = (
  { __typename?: 'BankInfo' }
  & Pick<BankInfo, 'id' | 'name' | 'bankName' | 'bankAccountNo' | 'country' | 'ic' | 'icImage' | 'selfie' | 'state' | 'rejectReason'>
);

export type ErrorFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'path' | 'detail'>
);

export type GameFragment = (
  { __typename?: 'Game' }
  & Pick<Game, 'id' | 'name' | 'iconUrl' | 'screenshotSampleUrl' | 'developerName'>
  & { gameRanks: Array<(
    { __typename?: 'GameRank' }
    & GameRankFragment
  )>, minRank?: Maybe<(
    { __typename?: 'GameRank' }
    & GameRankFragment
  )> }
);

export type GameAccountFragment = (
  { __typename?: 'GameAccount' }
  & Pick<GameAccount, 'id' | 'verificationCode' | 'accountId' | 'typeformUrl' | 'testRetryCount' | 'testScore' | 'testState' | 'codeState' | 'screenshotState' | 'screenshotUrl' | 'suspended' | 'rankTier' | 'rankStar' | 'rankPoint' | 'approve'>
  & { game: (
    { __typename?: 'Game' }
    & GameFragment
  ), gameRank: (
    { __typename?: 'GameRank' }
    & GameRankFragment
  ) }
);

export type GameBundleFragment = (
  { __typename?: 'GameBundle' }
  & Pick<GameBundle, 'id'>
);

export type GameRankFragment = (
  { __typename?: 'GameRank' }
  & Pick<GameRank, 'id' | 'name' | 'rankTierMaxCount' | 'rankStarMaxCount' | 'rankPointMaxCount' | 'ordering' | 'imageUrl'>
);

export type PilotBasicFragment = (
  { __typename?: 'Pilot' }
  & Pick<Pilot, 'id' | 'nickname' | 'email' | 'language' | 'gender' | 'avatarUrl' | 'signInCount' | 'lastSignInAt'>
);

export type AuthorizationMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  phoneCode: Scalars['String'];
}>;


export type AuthorizationMutation = (
  { __typename?: 'Mutation' }
  & { authorization?: Maybe<(
    { __typename?: 'AuthorizationPayload' }
    & Pick<AuthorizationPayload, 'jwt'>
    & { pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & PilotBasicFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type RequestPhoneCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type RequestPhoneCodeMutation = (
  { __typename?: 'Mutation' }
  & { requestPhoneCode?: Maybe<(
    { __typename?: 'RequestCodePayload' }
    & Pick<RequestCodePayload, 'phoneNumber'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SubmitBankInfoMutationVariables = Exact<{
  name: Scalars['String'];
  bankName: Scalars['String'];
  bankAccountNo: Scalars['String'];
  country: Scalars['String'];
  ic: Scalars['String'];
  icImage: Scalars['File'];
  selfie: Scalars['File'];
}>;


export type SubmitBankInfoMutation = (
  { __typename?: 'Mutation' }
  & { submitBankInfo?: Maybe<(
    { __typename?: 'SubmitInfoPayload' }
    & { bankInfo?: Maybe<(
      { __typename?: 'BankInfo' }
      & BankInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SubmitGameAccountCodeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubmitGameAccountCodeMutation = (
  { __typename?: 'Mutation' }
  & { submitGameAccountCode?: Maybe<(
    { __typename?: 'SubmitCodePayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & GameAccountFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type SubmitGameAccountDataMutationVariables = Exact<{
  gameId: Scalars['ID'];
  accountId: Scalars['String'];
  gameRankId?: Maybe<Scalars['ID']>;
  pairableGameRankIds?: Maybe<Array<Scalars['ID']>>;
  rankTier?: Maybe<Scalars['Int']>;
  rankStar?: Maybe<Scalars['Int']>;
  rankPoint?: Maybe<Scalars['Int']>;
  screenshot: Array<Scalars['File']>;
}>;


export type SubmitGameAccountDataMutation = (
  { __typename?: 'Mutation' }
  & { submitGameAccountData?: Maybe<(
    { __typename?: 'SubmitDataPayload' }
    & { gameAccount?: Maybe<(
      { __typename?: 'GameAccount' }
      & GameAccountFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateBankInfoMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAccountNo?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  ic?: Maybe<Scalars['String']>;
  icImage?: Maybe<Scalars['File']>;
  selfie?: Maybe<Scalars['File']>;
}>;


export type UpdateBankInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateBankInfo?: Maybe<(
    { __typename?: 'UpdateInfoPayload' }
    & { bankInfo?: Maybe<(
      { __typename?: 'BankInfo' }
      & BankInfoFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  nickname: Scalars['String'];
  email: Scalars['String'];
  gender: Gender;
  language?: Maybe<Language>;
  avatar?: Maybe<Scalars['File']>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'UpdatePayload' }
    & { pilot?: Maybe<(
      { __typename?: 'Pilot' }
      & PilotBasicFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & ErrorFragment
    )>> }
  )> }
);

export type GameQueryVariables = Exact<{ [key: string]: never; }>;


export type GameQuery = (
  { __typename?: 'Query' }
  & { games?: Maybe<Array<(
    { __typename?: 'Game' }
    & GameFragment
  )>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { pilotMe?: Maybe<(
    { __typename?: 'Pilot' }
    & PilotBasicFragment
  )> }
);

export type MeWithGameAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type MeWithGameAccountsQuery = (
  { __typename?: 'Query' }
  & { pilotMe?: Maybe<(
    { __typename?: 'Pilot' }
    & { gameAccounts: Array<(
      { __typename?: 'GameAccount' }
      & GameAccountFragment
    )> }
    & PilotBasicFragment
  )> }
);

export type MeWithBankInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MeWithBankInfoQuery = (
  { __typename?: 'Query' }
  & { pilotMe?: Maybe<(
    { __typename?: 'Pilot' }
    & { bankInfo?: Maybe<(
      { __typename?: 'BankInfo' }
      & BankInfoFragment
    )> }
    & PilotBasicFragment
  )> }
);

export type MeWithGameAccountsAndBankInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type MeWithGameAccountsAndBankInfoQuery = (
  { __typename?: 'Query' }
  & { pilotMe?: Maybe<(
    { __typename?: 'Pilot' }
    & { gameAccounts: Array<(
      { __typename?: 'GameAccount' }
      & GameAccountFragment
    )>, bankInfo?: Maybe<(
      { __typename?: 'BankInfo' }
      & BankInfoFragment
    )> }
    & PilotBasicFragment
  )> }
);

export const BankInfoFragmentDoc = gql`
    fragment BankInfo on BankInfo {
  id
  name
  bankName
  bankAccountNo
  country
  ic
  icImage
  selfie
  state
  rejectReason
}
    `;
export const ErrorFragmentDoc = gql`
    fragment Error on Error {
  path
  detail
}
    `;
export const GameRankFragmentDoc = gql`
    fragment GameRank on GameRank {
  id
  name
  rankTierMaxCount
  rankStarMaxCount
  rankPointMaxCount
  ordering
  imageUrl
}
    `;
export const GameFragmentDoc = gql`
    fragment Game on Game {
  id
  name
  iconUrl
  screenshotSampleUrl
  developerName
  gameRanks {
    ...GameRank
  }
  minRank {
    ...GameRank
  }
}
    ${GameRankFragmentDoc}`;
export const GameAccountFragmentDoc = gql`
    fragment GameAccount on GameAccount {
  id
  verificationCode
  accountId
  typeformUrl
  testRetryCount
  testScore
  testState
  codeState
  screenshotState
  screenshotUrl
  suspended
  game {
    ...Game
  }
  gameRank {
    ...GameRank
  }
  rankTier
  rankStar
  rankPoint
  approve
}
    ${GameFragmentDoc}
${GameRankFragmentDoc}`;
export const GameBundleFragmentDoc = gql`
    fragment GameBundle on GameBundle {
  id
}
    `;
export const PilotBasicFragmentDoc = gql`
    fragment PilotBasic on Pilot {
  id
  nickname
  email
  language
  gender
  avatarUrl
  signInCount
  lastSignInAt
}
    `;
export const AuthorizationDocument = gql`
    mutation Authorization($phoneNumber: String!, $phoneCode: String!) {
  authorization(input: {phoneNumber: $phoneNumber, phoneCode: $phoneCode}) {
    jwt
    pilot {
      ...PilotBasic
    }
    errors {
      ...Error
    }
  }
}
    ${PilotBasicFragmentDoc}
${ErrorFragmentDoc}`;
export type AuthorizationMutationFn = Apollo.MutationFunction<AuthorizationMutation, AuthorizationMutationVariables>;

/**
 * __useAuthorizationMutation__
 *
 * To run a mutation, you first call `useAuthorizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizationMutation, { data, loading, error }] = useAuthorizationMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneCode: // value for 'phoneCode'
 *   },
 * });
 */
export function useAuthorizationMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizationMutation, AuthorizationMutationVariables>) {
        return Apollo.useMutation<AuthorizationMutation, AuthorizationMutationVariables>(AuthorizationDocument, baseOptions);
      }
export type AuthorizationMutationHookResult = ReturnType<typeof useAuthorizationMutation>;
export type AuthorizationMutationResult = Apollo.MutationResult<AuthorizationMutation>;
export type AuthorizationMutationOptions = Apollo.BaseMutationOptions<AuthorizationMutation, AuthorizationMutationVariables>;
export const RequestPhoneCodeDocument = gql`
    mutation RequestPhoneCode($phoneNumber: String!) {
  requestPhoneCode(input: {phoneNumber: $phoneNumber}) {
    phoneNumber
    errors {
      ...Error
    }
  }
}
    ${ErrorFragmentDoc}`;
export type RequestPhoneCodeMutationFn = Apollo.MutationFunction<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>;

/**
 * __useRequestPhoneCodeMutation__
 *
 * To run a mutation, you first call `useRequestPhoneCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPhoneCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPhoneCodeMutation, { data, loading, error }] = useRequestPhoneCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRequestPhoneCodeMutation(baseOptions?: Apollo.MutationHookOptions<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>) {
        return Apollo.useMutation<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>(RequestPhoneCodeDocument, baseOptions);
      }
export type RequestPhoneCodeMutationHookResult = ReturnType<typeof useRequestPhoneCodeMutation>;
export type RequestPhoneCodeMutationResult = Apollo.MutationResult<RequestPhoneCodeMutation>;
export type RequestPhoneCodeMutationOptions = Apollo.BaseMutationOptions<RequestPhoneCodeMutation, RequestPhoneCodeMutationVariables>;
export const SubmitBankInfoDocument = gql`
    mutation SubmitBankInfo($name: String!, $bankName: String!, $bankAccountNo: String!, $country: String!, $ic: String!, $icImage: File!, $selfie: File!) {
  submitBankInfo(input: {name: $name, bankName: $bankName, bankAccountNo: $bankAccountNo, country: $country, ic: $ic, icImage: $icImage, selfie: $selfie}) {
    bankInfo {
      ...BankInfo
    }
    errors {
      ...Error
    }
  }
}
    ${BankInfoFragmentDoc}
${ErrorFragmentDoc}`;
export type SubmitBankInfoMutationFn = Apollo.MutationFunction<SubmitBankInfoMutation, SubmitBankInfoMutationVariables>;

/**
 * __useSubmitBankInfoMutation__
 *
 * To run a mutation, you first call `useSubmitBankInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBankInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBankInfoMutation, { data, loading, error }] = useSubmitBankInfoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      bankName: // value for 'bankName'
 *      bankAccountNo: // value for 'bankAccountNo'
 *      country: // value for 'country'
 *      ic: // value for 'ic'
 *      icImage: // value for 'icImage'
 *      selfie: // value for 'selfie'
 *   },
 * });
 */
export function useSubmitBankInfoMutation(baseOptions?: Apollo.MutationHookOptions<SubmitBankInfoMutation, SubmitBankInfoMutationVariables>) {
        return Apollo.useMutation<SubmitBankInfoMutation, SubmitBankInfoMutationVariables>(SubmitBankInfoDocument, baseOptions);
      }
export type SubmitBankInfoMutationHookResult = ReturnType<typeof useSubmitBankInfoMutation>;
export type SubmitBankInfoMutationResult = Apollo.MutationResult<SubmitBankInfoMutation>;
export type SubmitBankInfoMutationOptions = Apollo.BaseMutationOptions<SubmitBankInfoMutation, SubmitBankInfoMutationVariables>;
export const SubmitGameAccountCodeDocument = gql`
    mutation SubmitGameAccountCode($id: ID!) {
  submitGameAccountCode(input: {id: $id}) {
    gameAccount {
      ...GameAccount
    }
    errors {
      ...Error
    }
  }
}
    ${GameAccountFragmentDoc}
${ErrorFragmentDoc}`;
export type SubmitGameAccountCodeMutationFn = Apollo.MutationFunction<SubmitGameAccountCodeMutation, SubmitGameAccountCodeMutationVariables>;

/**
 * __useSubmitGameAccountCodeMutation__
 *
 * To run a mutation, you first call `useSubmitGameAccountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGameAccountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGameAccountCodeMutation, { data, loading, error }] = useSubmitGameAccountCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitGameAccountCodeMutation(baseOptions?: Apollo.MutationHookOptions<SubmitGameAccountCodeMutation, SubmitGameAccountCodeMutationVariables>) {
        return Apollo.useMutation<SubmitGameAccountCodeMutation, SubmitGameAccountCodeMutationVariables>(SubmitGameAccountCodeDocument, baseOptions);
      }
export type SubmitGameAccountCodeMutationHookResult = ReturnType<typeof useSubmitGameAccountCodeMutation>;
export type SubmitGameAccountCodeMutationResult = Apollo.MutationResult<SubmitGameAccountCodeMutation>;
export type SubmitGameAccountCodeMutationOptions = Apollo.BaseMutationOptions<SubmitGameAccountCodeMutation, SubmitGameAccountCodeMutationVariables>;
export const SubmitGameAccountDataDocument = gql`
    mutation SubmitGameAccountData($gameId: ID!, $accountId: String!, $gameRankId: ID, $pairableGameRankIds: [ID!], $rankTier: Int, $rankStar: Int, $rankPoint: Int, $screenshot: [File!]!) {
  submitGameAccountData(input: {gameId: $gameId, accountId: $accountId, gameRankId: $gameRankId, pairableGameRankIds: $pairableGameRankIds, rankTier: $rankTier, rankStar: $rankStar, rankPoint: $rankPoint, screenshot: $screenshot}) {
    gameAccount {
      ...GameAccount
    }
    errors {
      ...Error
    }
  }
}
    ${GameAccountFragmentDoc}
${ErrorFragmentDoc}`;
export type SubmitGameAccountDataMutationFn = Apollo.MutationFunction<SubmitGameAccountDataMutation, SubmitGameAccountDataMutationVariables>;

/**
 * __useSubmitGameAccountDataMutation__
 *
 * To run a mutation, you first call `useSubmitGameAccountDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGameAccountDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGameAccountDataMutation, { data, loading, error }] = useSubmitGameAccountDataMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      accountId: // value for 'accountId'
 *      gameRankId: // value for 'gameRankId'
 *      pairableGameRankIds: // value for 'pairableGameRankIds'
 *      rankTier: // value for 'rankTier'
 *      rankStar: // value for 'rankStar'
 *      rankPoint: // value for 'rankPoint'
 *      screenshot: // value for 'screenshot'
 *   },
 * });
 */
export function useSubmitGameAccountDataMutation(baseOptions?: Apollo.MutationHookOptions<SubmitGameAccountDataMutation, SubmitGameAccountDataMutationVariables>) {
        return Apollo.useMutation<SubmitGameAccountDataMutation, SubmitGameAccountDataMutationVariables>(SubmitGameAccountDataDocument, baseOptions);
      }
export type SubmitGameAccountDataMutationHookResult = ReturnType<typeof useSubmitGameAccountDataMutation>;
export type SubmitGameAccountDataMutationResult = Apollo.MutationResult<SubmitGameAccountDataMutation>;
export type SubmitGameAccountDataMutationOptions = Apollo.BaseMutationOptions<SubmitGameAccountDataMutation, SubmitGameAccountDataMutationVariables>;
export const UpdateBankInfoDocument = gql`
    mutation UpdateBankInfo($name: String, $bankName: String, $bankAccountNo: String, $country: String, $ic: String, $icImage: File, $selfie: File) {
  updateBankInfo(input: {name: $name, bankName: $bankName, bankAccountNo: $bankAccountNo, country: $country, ic: $ic, icImage: $icImage, selfie: $selfie}) {
    bankInfo {
      ...BankInfo
    }
    errors {
      ...Error
    }
  }
}
    ${BankInfoFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdateBankInfoMutationFn = Apollo.MutationFunction<UpdateBankInfoMutation, UpdateBankInfoMutationVariables>;

/**
 * __useUpdateBankInfoMutation__
 *
 * To run a mutation, you first call `useUpdateBankInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankInfoMutation, { data, loading, error }] = useUpdateBankInfoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      bankName: // value for 'bankName'
 *      bankAccountNo: // value for 'bankAccountNo'
 *      country: // value for 'country'
 *      ic: // value for 'ic'
 *      icImage: // value for 'icImage'
 *      selfie: // value for 'selfie'
 *   },
 * });
 */
export function useUpdateBankInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBankInfoMutation, UpdateBankInfoMutationVariables>) {
        return Apollo.useMutation<UpdateBankInfoMutation, UpdateBankInfoMutationVariables>(UpdateBankInfoDocument, baseOptions);
      }
export type UpdateBankInfoMutationHookResult = ReturnType<typeof useUpdateBankInfoMutation>;
export type UpdateBankInfoMutationResult = Apollo.MutationResult<UpdateBankInfoMutation>;
export type UpdateBankInfoMutationOptions = Apollo.BaseMutationOptions<UpdateBankInfoMutation, UpdateBankInfoMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($nickname: String!, $email: String!, $gender: Gender!, $language: Language, $avatar: File) {
  updateProfile(input: {nickname: $nickname, email: $email, gender: $gender, language: $language, avatar: $avatar}) {
    pilot {
      ...PilotBasic
    }
    errors {
      ...Error
    }
  }
}
    ${PilotBasicFragmentDoc}
${ErrorFragmentDoc}`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      nickname: // value for 'nickname'
 *      email: // value for 'email'
 *      gender: // value for 'gender'
 *      language: // value for 'language'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const GameDocument = gql`
    query Game {
  games {
    ...Game
  }
}
    ${GameFragmentDoc}`;

/**
 * __useGameQuery__
 *
 * To run a query within a React component, call `useGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGameQuery(baseOptions?: Apollo.QueryHookOptions<GameQuery, GameQueryVariables>) {
        return Apollo.useQuery<GameQuery, GameQueryVariables>(GameDocument, baseOptions);
      }
export function useGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameQuery, GameQueryVariables>) {
          return Apollo.useLazyQuery<GameQuery, GameQueryVariables>(GameDocument, baseOptions);
        }
export type GameQueryHookResult = ReturnType<typeof useGameQuery>;
export type GameLazyQueryHookResult = ReturnType<typeof useGameLazyQuery>;
export type GameQueryResult = Apollo.QueryResult<GameQuery, GameQueryVariables>;
export const MeDocument = gql`
    query Me {
  pilotMe {
    ...PilotBasic
  }
}
    ${PilotBasicFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeWithGameAccountsDocument = gql`
    query MeWithGameAccounts {
  pilotMe {
    ...PilotBasic
    gameAccounts {
      ...GameAccount
    }
  }
}
    ${PilotBasicFragmentDoc}
${GameAccountFragmentDoc}`;

/**
 * __useMeWithGameAccountsQuery__
 *
 * To run a query within a React component, call `useMeWithGameAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeWithGameAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeWithGameAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeWithGameAccountsQuery(baseOptions?: Apollo.QueryHookOptions<MeWithGameAccountsQuery, MeWithGameAccountsQueryVariables>) {
        return Apollo.useQuery<MeWithGameAccountsQuery, MeWithGameAccountsQueryVariables>(MeWithGameAccountsDocument, baseOptions);
      }
export function useMeWithGameAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeWithGameAccountsQuery, MeWithGameAccountsQueryVariables>) {
          return Apollo.useLazyQuery<MeWithGameAccountsQuery, MeWithGameAccountsQueryVariables>(MeWithGameAccountsDocument, baseOptions);
        }
export type MeWithGameAccountsQueryHookResult = ReturnType<typeof useMeWithGameAccountsQuery>;
export type MeWithGameAccountsLazyQueryHookResult = ReturnType<typeof useMeWithGameAccountsLazyQuery>;
export type MeWithGameAccountsQueryResult = Apollo.QueryResult<MeWithGameAccountsQuery, MeWithGameAccountsQueryVariables>;
export const MeWithBankInfoDocument = gql`
    query MeWithBankInfo {
  pilotMe {
    ...PilotBasic
    bankInfo {
      ...BankInfo
    }
  }
}
    ${PilotBasicFragmentDoc}
${BankInfoFragmentDoc}`;

/**
 * __useMeWithBankInfoQuery__
 *
 * To run a query within a React component, call `useMeWithBankInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeWithBankInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeWithBankInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeWithBankInfoQuery(baseOptions?: Apollo.QueryHookOptions<MeWithBankInfoQuery, MeWithBankInfoQueryVariables>) {
        return Apollo.useQuery<MeWithBankInfoQuery, MeWithBankInfoQueryVariables>(MeWithBankInfoDocument, baseOptions);
      }
export function useMeWithBankInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeWithBankInfoQuery, MeWithBankInfoQueryVariables>) {
          return Apollo.useLazyQuery<MeWithBankInfoQuery, MeWithBankInfoQueryVariables>(MeWithBankInfoDocument, baseOptions);
        }
export type MeWithBankInfoQueryHookResult = ReturnType<typeof useMeWithBankInfoQuery>;
export type MeWithBankInfoLazyQueryHookResult = ReturnType<typeof useMeWithBankInfoLazyQuery>;
export type MeWithBankInfoQueryResult = Apollo.QueryResult<MeWithBankInfoQuery, MeWithBankInfoQueryVariables>;
export const MeWithGameAccountsAndBankInfoDocument = gql`
    query MeWithGameAccountsAndBankInfo {
  pilotMe {
    ...PilotBasic
    gameAccounts {
      ...GameAccount
    }
    bankInfo {
      ...BankInfo
    }
  }
}
    ${PilotBasicFragmentDoc}
${GameAccountFragmentDoc}
${BankInfoFragmentDoc}`;

/**
 * __useMeWithGameAccountsAndBankInfoQuery__
 *
 * To run a query within a React component, call `useMeWithGameAccountsAndBankInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeWithGameAccountsAndBankInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeWithGameAccountsAndBankInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeWithGameAccountsAndBankInfoQuery(baseOptions?: Apollo.QueryHookOptions<MeWithGameAccountsAndBankInfoQuery, MeWithGameAccountsAndBankInfoQueryVariables>) {
        return Apollo.useQuery<MeWithGameAccountsAndBankInfoQuery, MeWithGameAccountsAndBankInfoQueryVariables>(MeWithGameAccountsAndBankInfoDocument, baseOptions);
      }
export function useMeWithGameAccountsAndBankInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeWithGameAccountsAndBankInfoQuery, MeWithGameAccountsAndBankInfoQueryVariables>) {
          return Apollo.useLazyQuery<MeWithGameAccountsAndBankInfoQuery, MeWithGameAccountsAndBankInfoQueryVariables>(MeWithGameAccountsAndBankInfoDocument, baseOptions);
        }
export type MeWithGameAccountsAndBankInfoQueryHookResult = ReturnType<typeof useMeWithGameAccountsAndBankInfoQuery>;
export type MeWithGameAccountsAndBankInfoLazyQueryHookResult = ReturnType<typeof useMeWithGameAccountsAndBankInfoLazyQuery>;
export type MeWithGameAccountsAndBankInfoQueryResult = Apollo.QueryResult<MeWithGameAccountsAndBankInfoQuery, MeWithGameAccountsAndBankInfoQueryVariables>;